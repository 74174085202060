import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import userProfileUtils from "../utils/userProfileUtils";
import * as contentTypes from '../constants/contentTypes';
import axios from 'axios';
import * as global from '../constants/global';

/**
 * Configurations API
 */
class ConfigurationsApi {
  static getAll() {
    const CONFIGURATIONS_ENDPOINT = apiEndpoints.CONFIGURATIONS_ENDPOINT;
    let user = userProfileUtils.getCurrentUser();
    let token = '';
    if (user !== null) {
      token = user.accessToken;
    }
    var formData = new FormData();
    formData.append('token', token);
    formData.append('product_code', global.AUTH_PRODUCT_CODE);
    return axios({
      method: httpMethods.REQUEST_METHOD_POST,
      url: CONFIGURATIONS_ENDPOINT,
      data: formData
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response;
    });
  }

  static getByDocument(document) {
    const CONFIGURATIONS_ENDPOINT = apiEndpoints.CONFIGURATIONS_BY_DOCUMENT_ENDPOINT(document);
    let user = userProfileUtils.getCurrentUser();
    let token = '';
    if (user !== null) {
      token = user.accessToken;
    }
    return axios({
      method: httpMethods.REQUEST_METHOD_GET,
      url: CONFIGURATIONS_ENDPOINT,
      headers: { 'product_code': global.AUTH_PRODUCT_CODE, 'Authorization': 'Bearer ' + token }
    }).then((response) => {
      return response.data;
    }).catch((error) => {
      return error.response;
    });
  }
}
export default ConfigurationsApi;