import React, { Component } from 'react';

import {
    Grid, Card, Typography,
    FormControlLabel, TextField,
    Checkbox,
    Button,
    Paper,
    FormControl,
    Select, MenuItem
} from "@material-ui/core";

import SettingsIcon from '@material-ui/icons/Settings';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SaveIcon from '@material-ui/icons/Save';

class ConfigurationCard extends Component {
    
    constructor(props) {
        super(props);
    }  
    
   

    render() {
    
        
        const { 
            handleInputsForm,
            inputStatusEntry,
            inputObservation,
            isEnabledStatusEntry,
            isEnabledObservation, 
            clearForm,
            saveConfiguration,
            autoFillInReceiverData,
            enableRecipientName,
            enableRecipientCpfCnpj,
            enableStubSignature,
            signatureType,
            stubType,
            searchNotificationByCnpjRoot,
            collect,
            enablePin,
            estimatedTimeDeletedStub,
            handleEstimatedTimeDeletedStub,
            enableNotifyAbsentReceiver
        } = this.props;
        let labelColorStatusEntry =  isEnabledStatusEntry? "#6c6b6b" : "#c5c5c5" ;
        let labelColorObservation =  isEnabledObservation? "#6c6b6b" : "#c5c5c5" ;  

   

        return (
            <form onSubmit={saveConfiguration}>
                <Card style={{padding:20, backgroundColor:"#ffff", marginTop:150}}>
                    <Paper style={{ marginBottom:10, padding:10, backgroundColor:"#fff7f2" }}  elevation={0} variant="outlined">                                            
                            <Typography style={{color:"#f75a26", fontWeight:500}}>
                                <SettingsIcon style={{color:"#f75a26", fontSize:20}}/>
                                Campos
                            </Typography>

                        <Grid container item md={12} direction="row">
                            <Grid container item md={12} alignItems="center" >
                                <Grid container item md={1}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleInputsForm} checked={isEnabledStatusEntry} name="isEnabledStatusEntry" type="checkbox" />}
                                    />
                                </Grid>
                                <Grid md={11}>
                                    <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                        Habilitar status da entrega ?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item md={12} direction="row">
                            <Grid container item md={12} alignItems="center" >
                                <Grid md={1}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleInputsForm} checked={isEnabledObservation} name="isEnabledObservation" type="checkbox" />}
                                    />
                                </Grid>
                                <Grid md={11}>
                                    <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                        Habilitar observações ?
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Paper>
                    <Paper style={{ marginBottom:10, padding:10,  backgroundColor:"#fff7f2" }}  elevation={0} variant="outlined">                                            
                                <Typography style={{color:"#f75a26", fontWeight:500}}>
                                    <SettingsIcon style={{color:"#f75a26", fontSize:20}}/>
                                    Outros
                                </Typography>
                        <Grid container direction='column'>
                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleInputsForm} checked={autoFillInReceiverData} name="autoFillInReceiverData" type="checkbox" />}                                  
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Preencher automaticamente os dados do recebedor ?
                                        </Typography>                                
                                    </Grid>                           
                                </Grid>
                            </Grid>
                            
                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleInputsForm} checked={enableRecipientCpfCnpj} name="enableRecipientCpfCnpj" type="checkbox" />}                                  
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Habilitar o CPF/CNPJ do recebedor como obrigatório ?
                                        </Typography>                                
                                    </Grid>                           
                                </Grid>
                            </Grid>

                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleInputsForm} checked={enableRecipientName} name="enableRecipientName" type="checkbox" />}                                  
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Habilitar o nome do recebedor como obrigatório ?
                                        </Typography>                                
                                    </Grid>                           
                                </Grid>
                            </Grid>

                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleInputsForm} checked={searchNotificationByCnpjRoot} name="searchNotificationByCnpjRoot" type="checkbox" />}                                  
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Habilitar notificações por CNPJ Raiz ?
                                        </Typography>                                
                                    </Grid>                           
                                </Grid>
                            </Grid>

                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={handleInputsForm}
                                                checked={enablePin}
                                                name="enablePin"
                                                type="checkbox" />}
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Habilitar senha para notificação?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={handleInputsForm}
                                                checked={collect}
                                                name="collect"
                                                type="checkbox" />}
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            COLETA - Gerar Pendência de Canhoto ?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={3}>
                                        <TextField 
                                            id="filled-basic" 
                                            label="minuto(s)"
                                            variant="filled" 
                                            size='small'
                                            value={estimatedTimeDeletedStub}
                                            onChange={handleEstimatedTimeDeletedStub}
                                            inputProps={{
                                                type: 'number',
                                                min: 0,
                                                max: 99,
                                                step: 1 
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={9}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Tempo permitido para deletar canhoto.
                                        </Typography>
                                    </Grid>
                                  
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                    <Paper style={{ marginBottom:10, padding:10,  backgroundColor:"#fff7f2" }}  elevation={0} variant="outlined">
                        <Typography style={{color:"#f75a26", fontWeight:500}}>
                            <SettingsIcon style={{color:"#f75a26", fontSize:20}}/>
                            Assinatura
                        </Typography>
                        <Grid container direction='column'>

                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleInputsForm}
                                                               checked={enableStubSignature}
                                                               name="enableStubSignature"
                                                               type="checkbox" />}
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Habilitar a assinatura no canhoto?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} direction="row">
                                <Grid container item md={12} alignItems="center" >
                                    <Grid md={1}>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleInputsForm}
                                                               checked={enableNotifyAbsentReceiver}
                                                               name="enableNotifyAbsentReceiver"
                                                               type="checkbox" />}
                                        />
                                    </Grid>
                                    <Grid md={11}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Notificar o procurador em caso de recebedor ausente?
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item md={12} direction="row">
                                <Grid container item md={9} alignItems="center">
                                    <Grid md={5}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Tipo de assinatura
                                        </Typography>
                                    </Grid>
                                    <Grid md={5}>
                                        <FormControl>
                                            <Select
                                                labelId="signatureType-label"
                                                id="signatureType"
                                                name="signatureType"
                                                value={signatureType}
                                                onChange={handleInputsForm}
                                            >
                                                <MenuItem value={'RUBRIC'}>RÚBRICA</MenuItem>
                                                <MenuItem value={'TYPETED'}>DIGITADO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid md={5}>
                                        <Typography style={{fontSize:11, color:"#6c6b6b", marginLeft: 5}} align="left">
                                            Tipo de Canhoto
                                        </Typography>
                                    </Grid>
                                    <Grid md={5}>
                                        <FormControl>
                                            <Select
                                                labelId="stubType-label"
                                                id="stubType"
                                                name="stubType"
                                                value={stubType}
                                                onChange={handleInputsForm}
                                            >
                                                <MenuItem value={'SIGNATURE'}>ASSINATURA</MenuItem>
                                                <MenuItem value={'PICTURE'}>FOTO</MenuItem>
                                                <MenuItem value={'BOTH'}>AMBOS</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item md={12}  direction="row" justifyContent="flex-end"  alignItems="center" style={{marginTop:15, marginBottom:5}}>
                        <Grid container item md={12} direction="row" justifyContent="flex-end"  alignItems="center">
                            <Button                             
                                style={{marginBottom:5}}
                                onClick={clearForm}
                                size="small" 
                                variant="outlined"
                                color="primary" 
                                startIcon={<RotateLeftIcon style={{color:'#f75a26'}}/>}>
                                Limpar 
                            </Button>          
                            <Button  
                                type='onSubmit'
                                style={{marginLeft:5, marginBottom:5}}                                  
                                variant="contained" 
                                size="small" 
                                color="primary"
                                startIcon={<SaveIcon style={{color:"#ffffff"}} />}>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        );
  }
}

export default ConfigurationCard;