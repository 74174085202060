import axios from 'axios';
import * as httpMethods from "../constants/httpMethods";
import * as global from "../constants/global";
import userProfileUtils from "../utils/userProfileUtils";
import * as contentTypes from "../constants/contentTypes";
import InputUtils from "../utils/inputUtils";

class DeliveryPerson {

    static all() {
        let company = userProfileUtils.getCurrentCompany();
        let userLocal = userProfileUtils.findLocalUser();
        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: global.STUB_HOST + `/companies/${company.id}/delivery-people?userId=${userLocal.id}&searchByAllCompanies=true`,
            headers: this.generateAxiosHeaders(),
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static save(parameters) {
        parameters.document = InputUtils.onlyNumbers(parameters.document != null ? parameters.document : '');
        parameters.phone = InputUtils.onlyNumbers(parameters.phone);
        let company = userProfileUtils.getCurrentCompany();
        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: global.STUB_HOST + `/companies/${company.id}/delivery-people`,
            headers: this.generateAxiosHeaders(),
            data: parameters
        }).then(response => {
            return response.data;
        });
    }

    static generateAxiosHeaders() {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token};
    }
}

export default DeliveryPerson;