import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

import { Grid } from "@material-ui/core";

import ConfigurationCard from './configurationCompany/ConfigurationCard';
import MobileExample from './configurationCompany/MobileExample';
import ConfigurationCompanyApi from '../../api/configurationCompanyApi';
import notificationUtils from '../../utils/notificationUtils';
import LoadingSpinner from '../../containers/LoadingSpinner';
import StubProfileUtils from '../../utils/stubProfileUtils';

class ConfigManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEnabledStatusEntry: false,
            isEnabledObservation: false,           
            inputStatusEntry: 'Situação da entrega',
            inputObservation: 'Observação', 
            showLoadingSpinner: false,
            autoFillInReceiverData: false,
            enableRecipientName: false,
            enableRecipientCpfCnpj: false,
            enableStubSignature: false,
            signatureType: 'RUBRIC',
            stubType:'SIGNATURE',
            searchNotificationByCnpjRoot: false,
            enablePin: false,
            collect: false,
            estimatedTimeDeletedStub:'',
            enableNotifyAbsentReceiver: false
        };
    } 
  
    componentDidMount() {
        this.loadData();       
    }

    handleLoadingSpinner = value => {
        this.setState({
            ...this.state,
            showLoadingSpinner: value,
        });
    };

    handleEstimatedTimeDeletedStub = (event) => {
        const inputValue = event.target.value;
        if (inputValue === '' || /^[0-9]{0,2}$/.test(inputValue)) {
          const numericValue = parseInt(inputValue, 10);
          if (isNaN(numericValue) || (numericValue >= 0 && numericValue <= 99)) {
            this.setState({estimatedTimeDeletedStub: inputValue });
          }
        }
      };

    loadData = () => {
        ConfigurationCompanyApi.filterConfigCompany().then(response => {
            const { message, config } = response;
            const { customFields, autoFillInReceiverData } = config;         
            if (message.success) {              
                this.setState({
                    ...this.state,
                    isEnabledStatusEntry: customFields.deliveryStatusEnabled,
                    isEnabledObservation: customFields.observationEnabled,
                    inputStatusEntry: customFields.deliveryStatusLabel,
                    inputObservation: customFields.observationLabel,
                    autoFillInReceiverData: autoFillInReceiverData,
                    enableRecipientName: customFields.enableRecipientName,
                    enableRecipientCpfCnpj: customFields.enableRecipientCpfCnpj,
                    enableStubSignature: customFields.enableStubSignature,
                    signatureType: customFields.signatureType,
                    stubType: customFields.stubType,
                    searchNotificationByCnpjRoot: customFields.searchNotificationByCnpjRoot,
                    enablePin: customFields.enablePin,
                    collect: customFields.collect,
                    estimatedTimeDeletedStub: customFields.estimatedTimeDeletedStub,
                    enableNotifyAbsentReceiver: customFields.enableNotifyAbsentReceiver
                });
            } else {               
                notificationUtils.warn(message.details);              
            }     
        }).catch(() => {
            this.handleLoadingSpinner(false);
            notificationUtils.error("API de Canhotos indisponível!");
        });
    }

    render() {
       return(    
            <Fragment>
                <Grid container spacing={3} direction="row" justifyContent="flex-end" alignItems='center' style={{marginTop:-50}}>
                    <Grid container  md={7}  direction="row" justifyContent="center" alignItems='flex-start' style={{marginTop:-180}}>
                       <ConfigurationCard 
                            handleInputsForm={this.handleInputsForm}
                            inputStatusEntry={this.state.inputStatusEntry}
                            inputObservation={this.state.inputObservation}
                            isEnabledStatusEntry={this.state.isEnabledStatusEntry}
                            isEnabledObservation={this.state.isEnabledObservation}                        
                            clearForm={this.clearForm}
                            saveConfiguration={this.saveConfiguration}
                            autoFillInReceiverData={this.state.autoFillInReceiverData}
                            enableRecipientName={this.state.enableRecipientName}
                            enableRecipientCpfCnpj={this.state.enableRecipientCpfCnpj}
                            enableStubSignature={this.state.enableStubSignature}
                            signatureType={this.state.signatureType}
                            stubType={this.state.stubType}
                            searchNotificationByCnpjRoot={this.state.searchNotificationByCnpjRoot}
                            enablePin={this.state.enablePin}
                            collect={this.state.collect}
                            estimatedTimeDeletedStub={this.state.estimatedTimeDeletedStub}
                            handleEstimatedTimeDeletedStub={this.handleEstimatedTimeDeletedStub}
                            enableNotifyAbsentReceiver={this.state.enableNotifyAbsentReceiver}
                            />
                    </Grid>
                    <Grid item  md={4} > 
                       <MobileExample 
                            isEnabledStatusEntry={this.state.isEnabledStatusEntry}
                            isEnabledObservation={this.state.isEnabledObservation}                    
                            inputStatusEntry={this.state.inputStatusEntry}
                            inputObservation={this.state.inputObservation}
                            />                       
                    </Grid>
                </Grid> 
                <LoadingSpinner visible={this.state.showLoadingSpinner}/>
            </Fragment>             
                   
       );
    }

    handleInputsForm = (evt) => {
        const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        this.setState({
            ...this.state,
            [evt.target.name]: value
        }); 
    } 

    saveConfiguration = (evt) => {
        evt.preventDefault();
        console.log('Cadastrado com sucesso!!!');
        let config = {
            autoFillInReceiverData: this.state.autoFillInReceiverData,
            customFields : {            
                observationEnabled: this.state.isEnabledObservation,
                observationLabel: this.state.isEnabledObservation ? this.state.inputObservation: "Observação" ,
                deliveryStatusEnabled: this.state.isEnabledStatusEntry, 
                deliveryStatusLabel: this.state.isEnabledStatusEntry ? this.state.inputStatusEntry: "Situação da entrega",  
                enableRecipientName:this.state.enableRecipientName,
                enableRecipientCpfCnpj: this.state.enableRecipientCpfCnpj,
                enableStubSignature: this.state.enableStubSignature,
                signatureType: this.state.signatureType,
                stubType: this.state.stubType,
                searchNotificationByCnpjRoot: this.state.searchNotificationByCnpjRoot,
                enablePin: this.state.enablePin,
                estimatedTimeDeletedStub: this.state.estimatedTimeDeletedStub,
                collect: this.state.collect,
                enableNotifyAbsentReceiver: this.state.enableNotifyAbsentReceiver
            }
        };
        
        this.handleLoadingSpinner(true);
        ConfigurationCompanyApi.updateConfigurationCompany(config).then(response =>{
            this.handleLoadingSpinner(false);
            const message = response.message;
            if (message.success) {
                notificationUtils.success(message.details);
                StubProfileUtils.updateStubProfile(config.customFields);
                this.setState({
                    ...this.state,
                    showLoadingSpinner: false,
                });               
            } else {
                notificationUtils.warn(message.details);
                this.setState({
                    ...this.state,
                    showLoadingSpinner: false,
                });               
            }
        }).catch(() => {
            this.handleLoadingSpinner(false);
            notificationUtils.error("API de Canhotos indisponível!");
        });       
    }

    clearForm = () => {
        this.setState({   
            autoFillInReceiverData: false,                  
            isEnabledStatusEntry: false,
            isEnabledObservation: false,            
            inputStatusEntry: 'Situação da entrega',
            inputObservation: 'Observação', 
            enableRecipientName: false,
            enableRecipientCpfCnpj: false,
            enableStubSignature: false,  
            enableNotifyAbsentReceiver: false,
            searchNotificationByCnpjRoot: false,
            enablePin: true,
            collect: false,
        });
    }

}

ConfigManage.propTypes = {
    items: PropTypes.array,
    loadTelephoneGrid: PropTypes.func
};

export default ConfigManage;
