import React, {useEffect, useState} from 'react';
import {
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField
} from "@material-ui/core";
import PendenciesApi from "../../../api/pendenciesApi";
import DeliveryPerson from "../../../api/deliveryPerson";
import {ValidatorForm} from "react-material-ui-form-validator";
import Input from "../../../containers/form/Input";
import NotificationUtils from "../../../utils/notificationUtils";
import InputUtils from "../../../utils/inputUtils";

const dialogAssociatePhone = (open, pendency, onClose, onStart, onAssociate, onComplete) => {

    const [phones, setPhones] = useState([]);
    const [adding, setAdding] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredPhones, setFilteredPhones] = useState([]);
    const [deliveryPerson, setDeliveryPerson] = useState({
        name: '',
        document: '',
        phone: '',
    });

    useEffect(() => {
        if (open) {
            loadPhones();
        }
    }, [open, pendency]);

    function setDeliveryPersonField(field, value) {
        setDeliveryPerson(deliveryPerson => ({
            ...deliveryPerson,
            [field]: value
        }));
    }

    function loadPhones() {
        DeliveryPerson.all()
            .then((response) => {
                let phones = response;
                phones = phones == null ? [] : phones;
                setPhones(phones);
                setFilteredPhones(phones);
            })
    }

    function saveDeliveryPerson() {
        onStart();
        DeliveryPerson.save(deliveryPerson)
            .then(() => {
                loadPhones();
                NotificationUtils.success('Entregador adicionado com sucesso.');
            })
            .catch(() => {
                NotificationUtils.error('Entregador já existe.');
            }).finally(() => {
                setAdding(!adding);
                onComplete();
                setDeliveryPerson({
                    name: '',
                    document: '',
                    phone: '',
                });
            });
    }

    function bindWithPhone(phone) {
        onStart();
        PendenciesApi
            .bindWithPhone(phone.phoneId, [pendency.id])
            .then(() => {
                setSearch('');
                onAssociate();
            });
    }

    function unbindWithPhone(phone) {
        onStart();
        PendenciesApi
            .unbindWithPhone(phone.phoneId, pendency.id)
            .then(() => {
                setSearch('');
                onAssociate();
            });
    }

    const handleSearchChange = (event) => {
        const searchValue = event.target.value;
        setSearch(searchValue);
        filterItems(searchValue);
    };

    const filterItems = (searchValue) => {
        if (!searchValue) {
            setFilteredPhones(phones);
        } else {
            const filtered = phones.filter(item =>
                item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                item.phoneNumber.includes(searchValue)
            );
            setFilteredPhones(filtered);
        }
    };

    return (
        <Dialog
                open={open}
                onClose={() => {
                    setSearch('');
                    onClose();
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
            >
            <DialogTitle id="alert-dialog-title">
                Número: {pendency.number} | Série: {pendency.serie} | Destinatário: {pendency.nameReceiver}
            </DialogTitle>
            <DialogContent>
                    <div className={adding ? '' : 'hidden'}>
                        <div>
                            <h2 className='text-center'>Novo entregador</h2>
                            <Grid container
                                  justifyContent="center"
                                  alignItems="center">

                                <CardContent>
                                    <ValidatorForm noValidate>
                                        <Grid container md={12} spacing={4} direction="row">
                                            <Grid md={12} item>
                                                <Input
                                                    validators={['required']}
                                                    errorMessages={['Este campo é obrigatório']}
                                                    label={<span>Nome <span style={{ color: 'red' }}>*</span></span>}
                                                    onChange={(e) => setDeliveryPersonField('name', e.target.value)}
                                                    value={deliveryPerson.name}
                                                />
                                            </Grid>
                                            <Grid md={12} item>
                                                <Input
                                                    validators={['required', 'minStringLength:15']}
                                                    errorMessages={['Este campo é obrigatório', 'Numero de celular incompleto']}
                                                    label={<span>Telefone <span style={{ color: 'red' }}>*</span></span>}
                                                    mask="phone"
                                                    onChange={(e) => setDeliveryPersonField('phone', e.target.value.slice(0, 30))}
                                                    value={deliveryPerson.phone}
                                                />
                                            </Grid>
                                            <Grid md={12} item>
                                                <Input
                                                    label={<span>CPF / CNPJ</span>}
                                                    mask="document"
                                                    onChange={(e) => setDeliveryPersonField('document', e.target.value.slice(0, 30))}
                                                    value={deliveryPerson.document}
                                                />
                                            </Grid>
                                            <Grid md={12} item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    fullWidth
                                                    onClick={() => saveDeliveryPerson()}>
                                                    Salvar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                </CardContent>
                            </Grid>
                        </div>
                    </div>
                <div className={adding ? 'hidden' : ''}>
                        <div>

                            <TextField
                                name="number"
                                label="Pesquisar"
                                value={search}
                                onChange={handleSearchChange}
                                variant="outlined"
                                placeholder="Pesquisar ..."
                            />
                            <hr/>
                        </div>
                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Número</th>
                                    <th scope="col">Ações</th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredPhones.map((phone, index) => (
                                    <tr key={index}>
                                        <td>{phone.name}</td>
                                        <td>{phone.phoneNumber}</td>
                                        <td>
                                            {pendency.phoneId === phone.phoneId &&
                                                <button className="btn btn-default"
                                                        onClick={() => unbindWithPhone(phone)}>
                                                    Desassociar
                                                </button>
                                            }
                                            {pendency.phoneId !== phone.phoneId &&
                                                <button className="btn btn-default"
                                                        onClick={() => bindWithPhone(phone)}>
                                                    Associar
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </DialogContent>
            <DialogActions>
                <Button onClick={() => setAdding(!adding)}>
                    {!adding ? 'Incluir Entregador' : 'Listar Entregadores'}
                </Button>
                <Button onClick={() => {
                    setSearch('');
                    onClose();
                }}>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default dialogAssociatePhone;