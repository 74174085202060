import * as apiEndpoints from '../constants/apiEndpoints';
import * as httpMethods from '../constants/httpMethods';
import axios from 'axios';
import * as global from '../constants/global';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";

class UsersApi {

    static checkLogin(username, password) {
        const TOKEN_ENDPOINT = apiEndpoints.TOKEN_ENDPOINT;

        var formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        formData.append('grant_type', global.AUTH_GRANT_TYPE);
        formData.append('product_code', global.AUTH_PRODUCT_CODE);
        formData.append('client_id', global.AUTH_CLIENT_ID);

        var base64Credentials = btoa(global.AUTH_CLIENT_ID + ':' + global.AUTH_SECRET);
        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: TOKEN_ENDPOINT,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'basic ' + base64Credentials
            },
            data: formData
        }).then((response) => {
            return response.data;
        }).catch((error) => {       
            let details = error.response.data.error_description;
            return {message: { success: false, details: details } };        
        });
    }

    static forgotPassword(email){
        var base64Credentials = btoa(global.AUTH_CLIENT_ID + ':' + global.AUTH_SECRET);
        let data = {
            email: email,            
        };
        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: apiEndpoints.USER_FORGOT_PASSWORD,
            headers: {
                'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 
                'Authorization': 'Basic ' + base64Credentials},
            data: JSON.stringify(data)
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404 || status == 500)
                return error.response.data;
            throw error;
        });
    }

    static findLocalUser() {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        let company = userProfileUtils.getCurrentCompany();
        let id = company.id;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: global.STUB_HOST + `/v2/companies/${id}/users/username?username=${user.username}`,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if ([400, 404, 500].includes(status)) {
                return error.response.data;
            }

            throw error;
        });
    }

    static getCompanies() {
        let user = userProfileUtils.getCurrentUser();
        let userLocal = userProfileUtils.findLocalUser();
        let token = user.accessToken;

        console.log(userLocal);

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: global.STUB_HOST + `/v2/users/${userLocal.id}/companies`,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if ([400, 404, 500].includes(status)) {
                return error.response.data;
            }

            throw error;
        });
    }
}

export default UsersApi;  