import React, { useState, useEffect, Fragment } from 'react';
import PendenciesApi from "../../../api/pendenciesApi";
import Grid from '../../../containers/grid/Grid';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import dialogDelete from "./dialogDelete";
import dialogAssociatePhone from "./dialogAssociatePhone";
import filterPendencies from "./filter";
import dialogBatchAssociate from "./dialogBatchAssociate";
import telephoneApi from "../../../api/telephoneApi";
import dialogLocation from "./dialogLocation";

const customFieldListView = (setShowLoadingSpinner) => {

    const [pendencies, setPendencies] = useState([]);
    const [pendency, setPendency] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openBatchAssociateDialog, setOpenBatchAssociateDialog] = useState(false);
    const [openAssociatePhoneDialog, setOpenAssociatePhoneDialog] = useState(false);
    const [isDialogLocationOpen, setIsDialogLocationOpen] = useState(false);
    const [location, setLocation] = useState({});
    const [pendenciesPhone, setPendenciesPhone] = useState([]);

    useEffect(() => {
        loadPendencies(false);
    }, [])
    
    function loadPendencies(showLoader) {
        setShowLoadingSpinner(showLoader);
        PendenciesApi.filterPendencies({
            documentType: [],
            searchByAllCompanies: true,
        })
            .then((response) => {
                let pendencies = response == null ? [] : response;
                setPendencies(pendencies);
                setShowLoadingSpinner(false);
            }
        )
    }

    function handlerDelete(ids) {
        setSelectedIds(ids);
        setOpenDeleteDialog(true);
    }

    function handlerBatchAssociate(ids) {
        setSelectedIds(ids);
        setOpenBatchAssociateDialog(true);
    }

    function handlerAssociate(pendency) {
        setPendency({
            serie: pendency[0],
            number: pendency[1],
            cnpjReceiver: pendency[3],
            nameReceiver: pendency[4],
            phoneId: pendency[5],
            id: pendency[6],
        },);
        setOpenAssociatePhoneDialog(true);
    }

    function handleOpenLocationDialog(value, location, pendencies) {
        setIsDialogLocationOpen(value);
        setLocation(location);
        setPendenciesPhone(pendencies);
    }

    const columns = [
        {
            name: 'series',
            label: 'Série',
            options: {},
        },
        {
            name: 'number',
            label: 'Número',
            options: {},
        },
        {
            name: "documentType",
            label: 'Tipo',
            options: {}
        },
        {
            name: 'recipientCnpjCpf',
            label: 'CNPJ Destinatário',
            options: {},
        },
        {
            name: 'recipientName',
            label: 'Nome Destinatário',
            options: {},
        },
        {
            name: "phoneId",
            options: {
                display: 'excluded',
                viewColumns: "false"
            }
        },
        {
            name: "id",
            options: {
                display: 'excluded',
                viewColumns: "false"
            }
        },
        {
            name: "phoneNumber",
            label: 'Telefone entregador',
            options: {}
        },
        {
            name: "nameOwnerPhone",
            label: 'Nome entregador',
            options: {}
        },
        {
            name: "actions",
            label: "Ações",
            options: {
                filter: false,
                sort: false,
                empty: true,
                viewColumns: false,
                download: false,
                customBodyRender: (value, tableMeta) => {
                    return (<div>
                        <Tooltip title='Associar telefone'>
                            <AddIcCallIcon style={{cursor: 'pointer', marginRight: '6px'}}
                                      onClick={() => {handlerAssociate(tableMeta.rowData)}} />
                        </Tooltip>
                        <Tooltip title='Remover pendência'>
                            <DeleteIcon style={{cursor: 'pointer', marginRight: '10px'}}
                                        onClick={() => {handlerDelete([tableMeta.rowData[6]])}} />
                        </Tooltip>
                        {(tableMeta.rowData[10] && <Tooltip title='Visualizar localização'>
                            <i style={{cursor: 'pointer'}} className={"fas fa-map-marker-alt"} onClick={() => {
                                let phoneId = tableMeta.rowData[5];
                                let pendenciesPromisse = telephoneApi.getPendenciesFromPhone(phoneId);
                                let locationPromisse = telephoneApi.findLastLocation(phoneId);
                                Promise.all([pendenciesPromisse, locationPromisse])
                                    .then((r) => {
                                        handleOpenLocationDialog(
                                            true,
                                            r[1].data,
                                            r[0].data,
                                        );
                                    });
                            }}>
                            </i>
                        </Tooltip>)}
                    </div>);
                }
            }
        },
        {
            name: "showLocation",
            options: {
                display: 'excluded',
                viewColumns: "false"
            }
        },
    ];

    const options = {
        searchPlaceholder: 'Pesquisar pendência',
        customToolbarSelect: (selectedRows, displayData) => (
            <div style={{margin: "0 15px"}}>
                <Tooltip title='Remover pendência'>
                    <DeleteIcon style={{cursor: 'pointer'}} onClick={() => {
                        let ids = [];
                        const deletableItems = selectedRows.data.map(item => displayData[item.index]);
                        deletableItems.forEach(item => ids.push([item.data[6]]));
                        handlerDelete(ids);
                    }}>
                    </DeleteIcon>
                </Tooltip>
                <Tooltip title='Associar telefone em lote'>
                    <AddIcCallIcon style={{cursor: 'pointer', marginLeft: '10px'}}
                                   onClick={() => {
                                       let ids = [];
                                       const deletableItems = selectedRows.data.map(item => displayData[item.index]);
                                       deletableItems.forEach(item => ids.push(item.data[6]));
                                       handlerBatchAssociate(ids);
                                   }}
                    />
                </Tooltip>
            </div>
        ),
    };

    return (
        <Fragment>
            {dialogLocation(
                isDialogLocationOpen,
                location,
                pendenciesPhone,
                () => setIsDialogLocationOpen(false),
            )}

            {dialogDelete(
                openDeleteDialog,
                selectedIds,
                () => setOpenDeleteDialog(false),
                () => {
                    setShowLoadingSpinner(true);
                    setOpenDeleteDialog(false);
                },
                () => {
                    setSelectedIds([]);
                    setShowLoadingSpinner(false);
                    loadPendencies(true)
                },
            )}

            {dialogBatchAssociate(
                openBatchAssociateDialog,
                selectedIds,
                () => setOpenBatchAssociateDialog(false),
                () => {
                    setShowLoadingSpinner(true);
                    setOpenBatchAssociateDialog(false);
                },
                () => {
                    setShowLoadingSpinner(false);
                    loadPendencies(true)
                },
            )}

            {dialogAssociatePhone(
                openAssociatePhoneDialog,
                pendency,
                () => {
                    setShowLoadingSpinner(false);
                    setOpenAssociatePhoneDialog(false);
                },
                () => setShowLoadingSpinner(true),
                () => {
                    setOpenAssociatePhoneDialog(false);
                    setShowLoadingSpinner(false);
                    loadPendencies(true);
                },
                () => {
                    setShowLoadingSpinner(false);
                    loadPendencies(true);
                },
            )}

            <Grid title={"Listagem de pendências de canhoto"}
                data={pendencies}
                columns={columns}
                options={options}
            />

            {filterPendencies((parameters) => {
                setShowLoadingSpinner(true);
                PendenciesApi.filterPendencies(parameters)
                    .then((response) => {
                        let pendencies = response == null ? [] : response;
                        setPendencies(pendencies);
                        setShowLoadingSpinner(false);
                    });

            }, () => {
                setShowLoadingSpinner(true);
                PendenciesApi.filterPendencies({
                    documentType: []
                })
                    .then((response) => {
                        let pendencies = response == null ? [] : response;
                        setPendencies(pendencies);
                        setShowLoadingSpinner(false);
                    });
            })}
        </Fragment>
    )
}

export default customFieldListView;