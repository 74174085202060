import * as httpMethods from '../constants/httpMethods';
import * as contentTypes from '../constants/contentTypes';
import userProfileUtils from "../utils/userProfileUtils";
import axios from 'axios';
import * as global from '../constants/global';
import DateUtils from "../utils/dateUtils";

const PENDENCIES_BASE_ENDPOINT = () => global.STUB_HOST + '/v1/stubs';
const PENDENCIES_ENDPOINT = () => PENDENCIES_BASE_ENDPOINT() + '/pendencies';

export default class PendenciesApi {

    static filterPendencies(parameters) {
        let user = userProfileUtils.getCurrentUser();
        let userLocal = userProfileUtils.findLocalUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_GET,
            url: PENDENCIES_ENDPOINT() + '/search',
            params: {
                companyId: parameters.searchByAllCompanies ? null : parameters.companyId,
                userId: userLocal.id,
                searchByAllCompanies: parameters.searchByAllCompanies,
                bondedWith: parameters.bound,
                recipientCnpjCpf: parameters.recipientCnpjCpf ? parameters.recipientCnpjCpf.replace(/\D/g, '') : null,
                documentType: parameters.documentType.length > 0 ? parameters.documentType.join(',') : null,
                number: parameters.number,
                recipientName: parameters.recipientName,
                startIssueDate: parameters.startIssueDate != null ? DateUtils.formatUTC(parameters.startIssueDate).split("T")[0].replaceAll('-', '/'): null,
                endIssueDate: parameters.endIssueDate != null ? DateUtils.formatUTC(parameters.endIssueDate).split("T")[0].replaceAll('-', '/'): null,
            },
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 400 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static deletePendency(pendencyId) {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_DELETE,
            url: global.STUB_HOST + '/stub/pendencies/' + pendencyId,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token}
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 403 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static bindWithPhone(phoneId, ids) {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_POST,
            url: global.STUB_HOST + `/v1/stub/phones/${phoneId}/pendencies/bind`,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
            data: JSON.stringify(ids),
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 403 || status == 404)
                return error.response.data;
            throw error;
        });
    }

    static unbindWithPhone(phoneId, pendencyId) {
        let user = userProfileUtils.getCurrentUser();
        let token = user.accessToken;

        return axios({
            method: httpMethods.REQUEST_METHOD_DELETE,
            url: global.STUB_HOST + `/v1/stub/phones/${phoneId}/pendencies/${pendencyId}`,
            headers: {'Content-Type': contentTypes.CONTENT_TYPE_APPLICATION_JSON, 'Authorization': 'Bearer ' + token},
        }).then(response => {
            return response.data;
        }).catch(error => {
            let status = error.response.status;
            if (status == 403 || status == 404)
                return error.response.data;
            throw error;
        });
    }


}