import * as global from './global';

/**
 * API Endpoints constants
 */
/* Global */
export const PRODUCTS_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/product';
export const CONFIGURATIONS_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/configurations';
export const CONFIGURATIONS_BY_DOCUMENT_ENDPOINT = (document) => global.AUTHORIZATION_SERVER_URL + '/configurations/' + document;
export const TOKEN_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/oauth/token';
export const GOOGLE_TOKEN_ENDPOINT = global.AUTHORIZATION_SERVER_URL + '/signin/google';

export const PHONE_LIST_ENDPOINT = (companyId) => global.STUB_PHONE_API_URL + '/companies/' + companyId;
export const PHONE_DELETE_ENDPOINT = global.STUB_PHONE_API_URL;
export const PHONE_NEW_ENDPOINT = global.STUB_PHONE_API_URL;
export const PHONE_RETRIEVE_ENDPOINT = (phoneId) => global.STUB_PHONE_API_URL + '/' + phoneId;
export const PHONE_UPDAET_ENDPOINT = (phoneId) => global.STUB_PHONE_API_URL + '/' + phoneId;

export const STUB_CSV_DOWNLOAD_ENDPOINT = global.STUB_API_URL + '/csv/download';
export const STUB_LIST_ENDPOINT = global.STUB_API_URL + '/list';
export const STUB_PROFILE_ENDPOINT = global.STUB_API_URL + '/profile';

export const STUB_DETAILS_ENDPOINT = global.STUB_API_URL;

export const STUB_DELETE_ENDPOINT = (companyId, stubId) => global.STUB_HOST + '/companies/' + companyId + '/stubs/' + stubId;

export const STUB_UPDATE_ENDPOINT = (stubId) => global.STUB_HOST + '/v1/stubs/' + stubId;

export const COMPANY_RETRIEVE_ENDPOINT = (document) => global.STUB_HOST + '/companies/' + document;

export const REVERSE_GEOCODING = (lat, lng) => "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&language=pt-BR&key=AIzaSyCrYZ8GU_BGaF_B5OJFLAhjPnIrLacz1k8";

export const USER_FORGOT_PASSWORD = global.AUTHORIZATION_SERVER_URL + "/user/forgotPassword";

export const CONFIG_COMPANY_ENDPOINT = (companyId) => global.STUB_HOST + '/v1/companies/' + companyId + '/config';

export const ACTUATOR_ENDPOINT = global.STUB_HOST + '/actuator/info';

export const PARAMETERS_ENDPOINT = (origin) => global.STUB_HOST + '/v1/parameters?origin=' + origin;



/* BankSlip */
// export const BANKSLIP_LIST_ENDPOINT = global.BANKSLIP_API_URL + '/list';
